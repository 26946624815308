import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { MdPhoneInTalk as Icon } from 'react-icons/md'
import Bounce from 'react-reveal/Bounce'

import Layout from '../components/Layout'

const Wrapper = styled.div`
background-color:#f2f2f2;
font-family: 'PT Sans Caption', sans-serif;
	display: flex;
	align-items: center;
	flex-direction: column;
  text-align: center;
  padding: 1rem;
  animation: hide;
  width:100%;
  
`

const Wrapper2 = styled.div`
background-color:#f2f2f2;
font-family: 'PT Sans Caption', sans-serif;
	display: flex;
	align-items: center;
	flex-direction: column;
  text-align: center;
  padding: 1rem;
  animation: hide;
  width:100%;
  
`

const Header = styled.h1`
  margin-top: 0;
  color: #ffff;
  background-color:#f2f2f2;
  margin-bottom: 2rem;
`

const Subheader = styled.h2`
  color: #ffff;
  margin:0;
  font-size: 5rem;
  border-radius: 20px;
  border: 5px solid #122A59;
  margin-top: 1rem;
  height: 7rem;
  width: 7rem;
  margin: 1rem auto 2rem;
`

const Text = styled.p`
  color: #122A59;
  font-size: ${props => props.big ? 1 : 1.3}rem;
`

const Button = styled.a.attrs({
  className: 'w-100 tc'
})`
  font-weight: bold;
  text-decoration: none;
  color: #f2f2f2;
  padding: 0.5rem 0;
  background-color: #122A59;
  border-radius: 5px;
  border: none;
  
`
const Logo2 = styled.img.attrs({
  alt: 'logo2'
})`
  max-width: 50%;
  max-height: 50%;
`



const Text2 = styled.div`
color: white;
padding-bottom:50px;

`

const Phone = styled.a`
color:#122A59;
font-size:30px;

`


const SimpleStarter12 = ({ data: { markdownRemark: { frontmatter } } }) =>
  <Layout frontmatter={{...frontmatter, backgroundColor: '#f2f2f2'}}>
    <Wrapper>
     
        <div>

          <Wrapper2>
            <Header><Logo2 src={frontmatter.logo2.publicURL} /></Header>
          </Wrapper2>
          
          <Text big>{frontmatter.text}</Text>
          <Bounce ssrReveal>
          <Subheader><a  href='tel:956-998-3233'><Icon color='#122A59' /></a></Subheader>
          </Bounce>
          <Text2 ><Phone  href='tel:956-998-3233'>{frontmatter.paragraph}</Phone></Text2>

          <section className='w-100 flex-column flex justify-center items-center'>
            <Button target='_blank' href='https://www.zreynalaw.com' color={frontmatter.primaryColor}>Visit Us</Button>
          </section>
          
        </div>
     
    </Wrapper>
  </Layout>

export default SimpleStarter12

export const pageQuery = graphql`
  query SimpleStarter12BySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        templateKey
        title
        description
        header
        subheader
        text
        text2
        paragraph
        buttonText
        link
        logo2 {
          publicURL
        }
        favicon {
          publicURL
        }
      }
    }
  }
`
